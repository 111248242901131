var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-template',{attrs:{"menuName":"Forum","backButton":true},on:{"backToPage":function($event){return _vm.moveToOther('APMA')}},scopedSlots:_vm._u([{key:"comment",fn:function(){return [_c('div',{staticClass:"s-input-fieldset"},[_c('fieldset',[_c('div',{staticClass:"s-p-20"},[_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll"}],staticClass:"s-pb-50 s-ht-500",staticStyle:{"overflow":"scroll"},attrs:{"id":"chat-room"}},_vm._l((_vm.temp_forum),function(c,i){return _c('div',{key:("chat-" + i)},[(c.sender_detail.id === _vm.user.id)?_c('div',{staticClass:"s-mb-30",attrs:{"id":"per-chat"}},[_c('div',{staticClass:"i-name-role s-mb-15"},[_c('div',{staticClass:"i-name s-ml-auto"},[_vm._v(" "+_vm._s(c.sender_detail.name)+" ")]),_c('div',{staticClass:"i-date-forum s-ml-auto s-mt-15"},[_vm._v(" "+_vm._s(_vm.convertDateTime(c.created_at))+" ")])]),_c('div',{staticClass:"s-flex",staticStyle:{"justify-content":"end"}},[_c('div',{class:("i-chat-category s-mr-50 s-mb-5 " + (c.category == 'Request'
                          ? 's-bg-primary s-text-white'
                          : c.category == 'Announcement'
                          ? 's-bg-secondary'
                          : 's-bg-success s-text-white'))},[_vm._v(" "+_vm._s(c.category)+" ")])]),_c('div',{staticClass:"s-flex s-mr-50",staticStyle:{"justify-content":"end"}},[_c('span',{staticClass:"i-chat-bubble i-right-side"},[_vm._v(" "+_vm._s(c.message)+" ")])])]):_vm._e(),(c.sender_detail.id !== _vm.user.id)?_c('div',{staticClass:"s-mb-30",attrs:{"id":"per-chat"}},[_c('div',{staticClass:"i-name-role s-mb-15"},[_c('div',{staticClass:"i-name s-mr-auto"},[_vm._v(" "+_vm._s(c.sender_detail.name)+" "),_c('span',{staticClass:"s-text-size-11"},[_vm._v("| "+_vm._s(c.sender_detail.role_name)+" ")])]),_c('div',{staticClass:"i-date-forum s-mr-auto s-mt-15"},[_vm._v(" "+_vm._s(_vm.convertDateTime(c.created_at))+" ")])]),_c('div',{staticClass:"s-flex",staticStyle:{"justify-content":"start"}},[_c('div',{class:("i-chat-category s-ml-50 s-mb-5 " + (c.category == 'Request'
                          ? 's-bg-primary s-text-white'
                          : c.category == 'Announcement'
                          ? 's-bg-secondary'
                          : 's-bg-success s-text-white'))},[_vm._v(" "+_vm._s(c.category)+" ")])]),_c('div',{staticClass:"s-flex s-ml-50",staticStyle:{"justify-content":"start"}},[_c('span',{staticClass:"i-chat-bubble i-left-side"},[_vm._v(" "+_vm._s(c.message)+" ")])])]):_vm._e()])}),0)])]),_c('div',{attrs:{"id":"new-message"}},[_c('div',{staticClass:"s-between-center-flex"},[_c('Select',{staticClass:"s-mtb-10 s-wd-40-p",attrs:{"label":_vm.$t('general.category'),"options":['Request', 'Question', 'Announcement'],"error":_vm.validation_category},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('Button',{staticClass:"s-wd-40-p",attrs:{"isLoading":_vm.isLoading},on:{"click":_vm.checkNewMessage}},[_vm._v(_vm._s(_vm.$t("APMA.checkNewMessage")))])],1),_c('Textarea',{attrs:{"placeholder":_vm.$t('APMA.typeMessage'),"error":_vm.validation_message},model:{value:(_vm.new_message),callback:function ($$v) {_vm.new_message=$$v},expression:"new_message"}})],1),_c('div',{staticClass:"s-mt-20 s-flex"},[_c('div',{staticClass:"s-ml-auto"},[_c('Button',{staticClass:"s-ml-20",on:{"click":function($event){$event.preventDefault();return _vm.onSendMessage.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("general.send")))])],1)])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }