<template>
  <div>
    <content-template
      menuName="Forum"
      :backButton="true"
      @backToPage="moveToOther('APMA')"
    >
      <template #comment>
        <div class="s-input-fieldset">
          <fieldset>
            <div class="s-p-20">
              <div
                id="chat-room"
                class="s-pb-50 s-ht-500"
                style="overflow: scroll"
                v-chat-scroll
              >
                <div v-for="(c, i) in temp_forum" :key="`chat-${i}`">
                    
                  <!-- RIGHT SIDE -->
                  <div
                    id="per-chat"
                    class="s-mb-30"
                    v-if="c.sender_detail.id === user.id"
                  >
                    <div class="i-name-role s-mb-15">
                      <div class="i-name s-ml-auto">
                        {{ c.sender_detail.name }}
                      </div>
                      <div class="i-date-forum s-ml-auto s-mt-15">
                        {{ convertDateTime(c.created_at) }}
                      </div>
                    </div>

                    <div class="s-flex" style="justify-content: end">
                      <div
                        :class="`i-chat-category s-mr-50 s-mb-5 ${
                          c.category == 'Request'
                            ? 's-bg-primary s-text-white'
                            : c.category == 'Announcement'
                            ? 's-bg-secondary'
                            : 's-bg-success s-text-white'
                        }`"
                      >
                        {{ c.category }}
                      </div>
                    </div>
                    <div class="s-flex s-mr-50" style="justify-content: end">
                      <span class="i-chat-bubble i-right-side">
                        {{ c.message }}
                      </span>
                    </div>
                  </div>
                  
                  <!-- LEFT SIDE -->
                  <div
                    id="per-chat"
                    class="s-mb-30"
                    v-if="c.sender_detail.id !== user.id"
                  >
                    <div class="i-name-role s-mb-15">
                      <div class="i-name s-mr-auto">
                        {{ c.sender_detail.name }} <span class="s-text-size-11" >| {{c.sender_detail.role_name}} </span>
                      </div>
                      <div class="i-date-forum s-mr-auto s-mt-15">
                        {{ convertDateTime(c.created_at) }}
                      </div>
                    </div>

                    <div class="s-flex" style="justify-content: start">
                      <div
                        :class="`i-chat-category s-ml-50 s-mb-5 ${
                          c.category == 'Request'
                            ? 's-bg-primary s-text-white'
                            : c.category == 'Announcement'
                            ? 's-bg-secondary'
                            : 's-bg-success s-text-white'
                        }`"
                      >
                        {{ c.category }}
                      </div>
                    </div>
                    <div class="s-flex s-ml-50" style="justify-content: start">
                      <span class="i-chat-bubble i-left-side">
                        {{ c.message }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div id="new-message">
            <div class="s-between-center-flex">
              <Select
                v-model="category"
                :label="$t('general.category')"
                class="s-mtb-10 s-wd-40-p"
                :options="['Request', 'Question', 'Announcement']"
                :error="validation_category"
              >
              </Select>
              <Button
                class="s-wd-40-p"
                @click="checkNewMessage"
                :isLoading="isLoading"
                >{{ $t("APMA.checkNewMessage") }}</Button
              >
            </div>
            <Textarea
              :placeholder="$t('APMA.typeMessage')"
              v-model="new_message"
              :error="validation_message"
            />
          </div>
          <div class="s-mt-20 s-flex">
            <div class="s-ml-auto">
              <Button @click.prevent="onSendMessage" class="s-ml-20">{{
                $t("general.send")
              }}</Button>
            </div>
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import form from "@/mixins/form";
import ContentTemplate from "./contentTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import { convertDateTime, convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form],
  data() {
    return {
      isLoading: false,
      new_message: "",
      category: "",
      validation_category: false,
      validation_message: false,
      temp_forum: [],
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      forum: "actionplan/getForum",
      user: "auth/getUser",
    }),
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions({
      setForum: "actionplan/setForumData",
      postForum: "actionplan/postForumData",
      setUserData: "auth/setUserData",
    }),
    editItem() {
      this.edited_item = Object.assign({}, this.item);
    },

    async onSendMessage() {
      this.validation_message = this.new_message == "" ? true : false;
      this.validation_category = this.category == "" ? true : false;

      if (!this.validation_category && !this.validation_message) {
        const data = {
          room_id: this.$route.params.room_id,
          message: this.new_message,
          category: this.category,
        };

        await this.postForum(data);
        // await this.setForum(this.$route.params.room_id);
        if (this.$store.getters["actionplan/getStatus"] == 1) {
          var temp_obj = {};
          var sub_obj = {};

          temp_obj.message = this.new_message;
          temp_obj.category = this.category;

          sub_obj.id = this.user.id;
          sub_obj.name = this.user.name;
          temp_obj.sender_detail = sub_obj;
          temp_obj.created_at = new Date();

          this.temp_forum.push(temp_obj);

          //Reset
          this.new_message = "";
          this.category = "";
        }
      }
    },
    async initData() {
       this.setLoadingPage(true)
      await this.setForum(this.$route.params.room_id);
      if (this.$store.getters["actionplan/getStatus"] == 1) {
        this.temp_forum = [...this.forum];
      }
      await this.setUserData();
       this.setLoadingPage(false)
    },

    async checkNewMessage() {
      this.isLoading = true;
      await this.setForum(this.$route.params.room_id);
      this.temp_forum = [...this.forum];
      this.isLoading = false;
    },

    moveToFinish() {
      this.$router.push(this.$translate({ name: "APMA Finish Task" }));
    },
    convertDate(value) {
      return convertDate(value);
    },
    convertDateTime(value) {
      return convertDateTime(value);
    },
  },
};
</script>
